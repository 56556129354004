html {
  font-size: 62.5%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-size: 2.4rem;
  font-family: sans-serif;
  color: #5a3e2b;
  font-family: "Quicksand";
  font-weight: 500;
  height: 100%;
  margin: 0;
  padding: 0;
}

h1 {
  text-align: center;
  background-color: #060402;
  font-family: "Times New Roman", Times, serif;
  font-size: 4rem;
  text-transform: uppercase;
  font-weight: 400;
  word-spacing: 20px;
  letter-spacing: -5px;
  padding: 2.4rem 1;
  margin-top: auto;
  height: 30vh;
  /* font-size: 7rem;
  text-transform: none; */
}

.letter_rotation {
  display: inline-block;
  transform: rotate(-30deg);
  font-size: 50px;
  color: blue;
  /* letter-spacing: 1px; */
}

.app {
  position: relative;
  width: 100%;
  height: 18vh;
  display: grid;
  grid-template-rows: auto auto 1fr auto;
  color: rgb(255, 255, 255);
  text-align: center;
  align-items: center;
  display: flex;
  /* justify-content: center; */
  margin-bottom: 1px;
  letter-spacing: 0.1px;
  box-shadow: 3px 3px 11px rgb(52, 181, 251);
}
.app p {
  margin-left: 90px;
  font-size: 35px;
}

.link_name_logo {
  position: absolute;
  text-align: left;
  font-size: 40px;
  margin-left: 5px;
}
.menu_button {
  position: absolute;
  top: auto;
  left: 20px;
  background-color: transparent;
  border: none;
  font-size: 34px;
  cursor: pointer;
  text-align: left;
  color: rgb(255, 255, 255);
}
.appointment_button {
  font-size: 20px;
  background-color: rgb(0, 0, 0);
  height: 65px;
  width: 230px;
  transition: all 1.3s ease; /* Animation Effect when the button is clicked! All changes are made within 1.3 second */
  color: rgb(255, 255, 255);
  box-shadow: 5px 5px 10px rgb(52, 181, 251);
  /* box-shadow: inset 2px 2px 4px rgba(68, 246, 112, 0.5); */
  text-shadow: 2px 2px 2px rgba(244, 6, 6, 0.99);
  border-radius: 10px;
}
.appointment_button:hover {
  color: black;
  background-color: rgb(255, 255, 255);
  box-shadow: 5px 5px 10px rgb(250, 14, 14);
}
/*"a" allows css properties only for the button, not the ones inherited from the parent*/
.appointment_button a {
  color: inherit;
  text-decoration: none; /*  Remove link underlining */
}
.form_title {
  display: flex;
  justify-content: inherit;
  font-size: 38px;
  font-weight: bold;
}
.input {
  display: flex;
  width: 80%; /* It occupies the entire available width*/
  height: 50px;
  margin-bottom: 10px;
  flex-direction: column; /* Places inputs vertically */
  align-items: center;
  font-size: 25px;
}
.container_input {
  display: flex;
  flex-direction: column; /* Places inputs vertically */
  align-items: center;
}
input::placeholder {
  font-size: 18px; /* Choose the size you want for the placeholder */
  color: #999; /*Choose the color you want for the placeholder */
}
.menu {
  position: absolute;
  width: 35%;
  height: 400px;
  top: 75%;
  left: 30px;
  font-size: 52px;
  font-weight: bold;
  text-align: left;
  background-color: rgb(9, 225, 249);
  z-index: 9; /* It is superior to any other element on the main page */
}

.logo_picture {
  background-image: url("/public/CreativeBeautyStudio.jpg");
  background-size: cover; /* This will ensure that the image will fit inside the div */
  height: 110px; /* The height and width can be adjusted according to your needs. */
  width: 115px;
  position: absolute;
  margin-left: 1165px;
  transition: transform 0.3s ease; /* This will ensure a smooth transition when the image zooms in */
  border-radius: 50%;
  /* box-shadow: 3px 3px 11px rgb(52, 181, 251); */
  box-shadow: inset -2px -3px 6px rgba(60, 192, 249, 0.975);
}
.logo_picture:hover {
  transform: scale(
    1.3
  ); /* This will enlarge the image by 20% when you hover over it */
}
.hair-page {
  /* background-image: url("/public/hair.jpg"); */
  position: relative;
  background-size: cover; /* Makes the image cover the entire container */
  background-position: center;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  padding: 50px; /* Add padding to separate the text from the border */
  color: rgb(105, 218, 246);
  height: 100%;
  width: 100%;
  text-align: center;
}
.hair-page::before {
  content: "";
  background-image: url("/public/hair.jpg");
  background-size: cover;
  background-position: center;
  position: absolute; /*  place the absolute pseudo-element over the background */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: blur(3px); /*  apply the blur effect only to this additional layer */
  z-index: -1; /*Make sure this layer is placed below the content of the .home container */
}
.hair-text-title {
  font-size: 50px;
}
.hair-text {
  max-width: 50%; /* Set max width to 50% */
  text-align: left;
  /* font-size: 25px; */
}
.hair_services_section {
  position: absolute;
  border: 5px solid black;
  padding: 10px;
  margin: 10px;
  right: 8%;
  top: 20%;
  transform: translateY(-50%);
  background-color: beige;
  width: 400px;
  height: auto;
}
.hair_treatments_section {
  position: absolute;
  border: 5px solid black;
  padding: 10px;
  margin: 10px;
  right: 8%;
  top: 45%;
  transform: translateY(-50%);
  background-color: beige;
  width: 400px;
  height: auto;
}
.gallery-container {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 20px;
  /* width: 200px;
  height: 200px; */
}
.image-gallery {
  max-width: 100%;
  height: auto;
  margin: 15px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
.image-gallery:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.image-gallery-description {
  padding: 10px;
  text-align: center;
  color: #333;
  font-size: 14px;
}

.manicure_pedicure-page {
  /* background-image: url("/public/manicure_pedicure.jpg"); */
  position: relative;
  background-size: cover; /* Makes the image cover the entire container */
  background-position: center;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  padding: 50px; /* Add padding to separate the text from the border */
  color: rgb(105, 218, 246);
  height: 100%;
  width: 100%;
  text-align: center;
}
.manicure_pedicure-page::before {
  content: "";
  background-image: url("/public/manicure_pedicure.jpg");
  background-size: cover;
  background-position: center;
  position: absolute; /*  place the absolute pseudo-element over the background */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: blur(3px); /*  apply the blur effect only to this additional layer */
  z-index: -1; /*Make sure this layer is placed below the content of the .home container */
}

.manicure_pedicure-text-title {
  font-size: 50px;
}
.manicure_pedicure-text {
  max-width: 50%; /* Set max width to 50% */
  text-align: left;
}
.cosmetics-makeup-page {
  /* background-image: url("/public/cosmetics&makeup.jpg"); */
  position: relative;
  background-size: cover; /* Face ca imaginea să acopere întregul container */
  background-position: center;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  padding: 50px; /* Add padding to separate the text from the border*/
  color: rgb(105, 218, 246);
  height: 100%;
  width: 100%;
  text-align: center;
}
.cosmetics-makeup-page::before {
  content: "";
  background-image: url("/public/cosmetics&makeup.jpg");
  background-size: cover;
  background-position: center;
  position: absolute; /*  place the absolute pseudo-element over the background */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: blur(4px); /*  apply the blur effect only to this additional layer */
  z-index: -1; /*Make sure this layer is placed below the content of the .home container */
}
.cosmetics-makeup-text-title {
  font-size: 50px;
}
.cosmetics-makeup-text {
  max-width: 50%; /* Set max width to 50% */
  text-align: left;
}
.person-name {
  color: crimson;
}
.home-container {
  /*  background-image: url("/public/kyle-smith-4qYHqQqlwM4-unsplash.jpg"); Specify the path to the desired image */
  position: relative;
  background-size: cover; /* Makes the image cover the entire container */
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* padding: 50px; Add padding to separate the text from the border */
  color: rgb(4, 4, 4);
  height: 100%;
  width: 100%;
  margin-top: 0px;
}
/*  add a ::before pseudo-element to create an extra layer over the background */
.home-container::before {
  content: "";
  background-image: url("/public/kyle-smith-4qYHqQqlwM4-unsplash.jpg");
  background-size: cover;
  background-position: center;
  position: absolute; /*  place the absolute pseudo-element over the background */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: blur(3px); /*  apply the blur effect only to this additional layer */
  z-index: -1; /*Make sure this layer is placed below the content of the .home container */
}

.home-title {
  text-align: center; /* Override the alignment of the text to the center of the page */
  /* background-color: white; */
  font-family: "Times New Roman", Times, serif;
  font-size: 6rem;
  text-transform: uppercase;
  font-weight: 400;
  word-spacing: 30px;
  letter-spacing: -5px;
  padding: 2.4rem 1;
  margin-bottom: -10px;
  height: 30vh;
  font-weight: bold;
}

.home-subtitle {
  font-weight: bold;
  font-size: 44px;
  margin-top: -60px;
  margin-bottom: 10px;
}

.home-description {
  font-size: 26px;
  margin-bottom: 20px;
  text-align: center;
}
.header-links-container {
  position: absolute; /*  position the container relative to the .app element */
  bottom: 0; /*  display the links under the text "Creative Beauty Studio" */
  left: 28%; /*  center the links in relation to the text */
  transform: translateX(-50%); /*  center the links horizontally */
  display: flex;
}

.header-link {
  font-size: 15px;
  color: #ffffff;
  text-decoration: none;
  margin: 0 50px; /*  add a space between the links */
  letter-spacing: 1px; /* add a space between the leters */
  transition: transform 0.3s ease;
}
.header-link:hover {
  transform: scale(
    1.8
  ); /* This will enlarge the image by 20% when you hover over it */
  color: #00bfff;
}
.header-contact-container {
  position: absolute; /*   position the container relative to the .app element */
  right: -7%;
  top: 50%;
  transform: translateX(-50%); /*  center the links horizontally */
  display: flex;
}
.header-contact-link {
  font-size: 15px;
  color: #ffffff;
  text-decoration: none;
  margin: 0 10px; /*  add a space between the links */
  word-spacing: 2px;
  /*letter-spacing: 1px;  add a space between the leters */
  transition: transform 0.3s ease;
  box-shadow: 3px 3px 11px rgb(52, 181, 251);
}
.header-contact-container p {
  font-size: 16px;
}
.header-contact-link:hover {
  transform: scale(1.8);
  cursor: pointer;
  color: #00bfff;
  background-color: black;
}
@media only screen and (max-width: 576px) {
  /* Adjust font size and padding for smaller screens */
  h1 {
    font-size: 4rem;
    padding: 1.6rem 1;
    height: auto;
    width: auto;
  }
  .app {
    height: auto;
    width: auto;
    padding: 20px;
  }
  .letter_rotation {
    font-size: 40px;
  }

  .home-title {
    font-size: 4rem;
    padding: 1.8rem 1;
  }
  .home-subtitle {
    font-size: 36px;
    margin-top: -40px;
  }
  .menu_button {
    font-size: 3rem;
    top: 55%;
    color: chartreuse;
  }

  .appointment_button {
    font-size: 16px;
    height: 50px;
    width: 200px;
  }

  .form_title {
    font-size: 28px;
  }

  .input {
    font-size: 20px;
  }

  .menu {
    font-size: 36px;
  }

  .logo_picture {
    height: 80px;
    width: 85px;
  }
}

@media only screen and (max-width: 768px) {
  /* Further adjust font size and padding for smaller screens */
  h1 {
    font-size: 3rem;
    padding: 1.2rem 0.8rem;
    height: auto;
    width: auto;
  }
  .app {
    height: auto;
    width: auto;
    padding: 10px;
  }
  .letter_rotation {
    font-size: 30px;
  }
  .home-title {
    font-size: 3rem;
    padding: 1.4rem 1;
  }
  .home-subtitle {
    font-size: 28px;
    margin-top: -20px;
  }
  .menu_button {
    font-size: 2.5rem;
    top: 55%;
    color: chartreuse;
  }

  .appointment_button {
    font-size: 14px;
    height: 45px;
    width: 180px;
  }

  .form_title {
    font-size: 24px;
  }

  .input {
    font-size: 18px;
  }

  .menu {
    font-size: 30px;
  }

  .logo_picture {
    height: 60px;
    width: 65px;
  }
}
